<template>
<v-container class="pa-5">
      <v-row no-gutters justify="center">
     <v-col
      sm="4"
     >
       <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
            <v-card style="background: none;" class="pa-2 elevation-0">
              <p class="text-center pt-5">
              <img :src="$store.state.systemlogo" width="120px"/>
              </p>
              <v-card-text>
                    
                      <v-text-field
                        v-model="username"
                        :rules="nameRules"
                        label="Username"
                        required
                        outlined
                      ></v-text-field>
                   
                      <v-text-field
                        v-model="password"
                        :rules="nameRules"
                        label="Password"
                        type="password"
                        v-on:keyup.enter="validate()"
                        required
                        outlined
                      ></v-text-field>
                    
              </v-card-text>
              <v-card-actions class="pt-4 pb-5">
                  <v-btn
                        color="primary"
                        block
                        rounded
                        large
                        :loading="loading"
                        @click="loading=true,validate()"
                      >
                        LOGIN
                      </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
     </v-col>
   </v-row>
</v-container>
</template>
<script>
//import jwt from 'jsonwebtoken'
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      valid: true,
      username: '',
      nameRules: [
        v => !!v || 'Field is required'
      ],
      password: '',
      loading: false,
      snackbar: false,
    }),
    created() {
      if (this.$session.exists()) {
          this.setLoggedIn(false)
          this.$session.destroy()
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      
      validate () {
        if (this.$refs.form.validate()) {
          this.login()
        }
      },
      login(){
          // var auth = jwt.sign({
          // username: this.username,
          // password: this.password,
          // }, 'this_thing_is_cool', { expiresIn: 60});
          // console.log(auth)
          var param = {
            username: this.username,
          password: this.password,
          }
          this.$http.post("user/login",param).then(response => {
          console.log( response.data)
          if(response.data.status == true) {
            this.$session.start()
            this.$session.set('jwt', response.data.account.token)
            this.$session.set('username', response.data.account.username)
            this.$session.set('role', response.data.account.role)
            this.$session.set('userID', response.data.account.id)
             this.$session.set('areaID', response.data.account.area)
             this.$session.set('fullname', response.data.account.fullname)
             this.$session.set('commission', response.data.account.commission)
            
            this.setAlertColor('success')
           
            this.SET_PERMISSION('game_set_up')
            this.SET_PERMISSION('game_stat_box')
           // this.SET_PERMISSION('set_admin')
              setTimeout(()=>{
                this.loading=false
                 this.setLoggedIn(true)
                   if(response.data.account.role == 'Agent') {
                  this.$router.push('/runner')
                } else {
                  this.$router.push('/dashboard')
                }
              }, 700)
               
            
          } else {
            this.setAlertColor('error')
            this.setLoggedIn(false)
             this.loading=false
          }
            this.setAlertText(response.data.message)
            this.setAlert(true)
          }).catch(e => {
           console.log(e)
         })
        
          
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
  }
</script>